import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

const OrganizationData = () => {
  const data = useStaticQuery(graphql`
    query organizationData {
      businessInfo: file(relativePath: { eq: "business-info.md" }) {
        childMarkdownRemark {
          frontmatter {
            name
            squareLogo {
              childImageSharp {
                original {
                  src
                }
              }
            }
            businessImage {
              childImageSharp {
                original {
                  src
                }
              }
            }
            city
            state
            zip
            email
          }
        }
      }
    }
  `)

  const { name, squareLogo, businessImage, city, state, zip, email } =
    data.businessInfo.childMarkdownRemark.frontmatter

  return (
    <Helmet>
      <script type='application/ld+json'>
        {`
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "@id": "https://www.iliadgrow.com",
            "name": "${name}",
            "logo": "https://www.iliadgrow.com${squareLogo.childImageSharp.original.src}",
            "image": "https://www.iliadgrow.com${businessImage.childImageSharp.original.src}",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": null,
              "addressLocality": "${city}",
              "addressRegion": "${state}",
              "postalCode": "${zip}",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 42.765583,
              "longitude": -84.565363
            },
            "url": "https://www.iliadgrow.com",
            "telephone": null,
            "email": "${email}",
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "09:00",
              "closes": "17:00"
            },
            "sameAs": [
              "https://instagram.com/iliadgrow",
              "https://www.facebook.com/iliadgrow"
            ]
          }
        `}
      </script>
    </Helmet>
  )
}

export default OrganizationData
